import Joi from "joi-browser";
import Model from "./Model";
const groups = { PRIMARY: "primary", TOGGLE: "toggle" };

class StoreModel extends Model {
  constructor() {
    super();
    this.id = this.createField(null, groups.PRIMARY, "id", Joi.number().allow(null));
    this.name = this.createField("", groups.PRIMARY, "Store name", Joi.string().allow(""));
    this.location = this.createField(
      "",
      groups.PRIMARY,
      "Location",
      Joi.string()
        .regex(/^[a-z0-9-]+$/)
        .required()
    );
    this.telegram_group_id = this.createField("", groups.PRIMARY, "Telegram Group ID", Joi.string().required());
    this.simulation = this.createField(false, groups.PRIMARY, "Simulation", Joi.boolean());
    this.store_recheckings = this.createField(
      parseInt(process.env.REACT_APP_MIN_RECHECKING),
      groups.PRIMARY,
      "Store recheckings",
      Joi.number()
        .min(parseInt(process.env.REACT_APP_MIN_RECHECKING))
        .max(parseInt(process.env.REACT_APP_MAX_RECHECKING))
    );
    this.recheck_alerting_mode = this.createField('any_relevant', groups.PRIMARY, "Recheck alerting mode", Joi.string());

    this.alert_expiration = this.createField(
      parseInt(process.env.REACT_APP_DEFAULT_ALERT_EXPIRATION),
      groups.PRIMARY,
      "Alert expiration",
      Joi.number()
        .min(parseInt(process.env.REACT_APP_MIN_ALERT_EXPIRATION))
        .max(parseInt(process.env.REACT_APP_MAX_ALERT_EXPIRATION))
    );
    this.simulation_threshold = this.createField(0.1, groups.PRIMARY, "Simulation threshold", Joi.number().allow(""));
    this.alert_channel = this.createField(0, groups.PRIMARY, "Alert Channel", Joi.number().allow(null));
    this.language = this.createField(1, groups.PRIMARY, "Language", Joi.number());
    this.timezone = this.createField(1, groups.PRIMARY, "TimeZone", Joi.number());
    this.buttons_type = this.createField(1, groups.PRIMARY, "Buttons Type", Joi.number());
    this.slack_channel = this.createField(0, groups.PRIMARY, "Slack Channel", Joi.number().allow(null));
    this.store_manager = this.createField(3, groups.PRIMARY, "Store manager", Joi.number());
    this.local_inference = this.createField(true, groups.PRIMARY, "Local inferences", Joi.boolean());
    this.store_favor_factor = this.createField(
      9.5,
      groups.PRIMARY,
      "Store favor factor",
      Joi.number()
        .min(parseInt(process.env.REACT_APP_MIN_FAVOR_FACTOR))
        .max(parseInt(process.env.REACT_APP_MAX_FAVOR_FACTOR))
        .allow("", null)
    );
    this.see_more_compression = this.createField(
      "",
      groups.PRIMARY,
      "See more compression",
      Joi.number().allow("", null)
    );
    this.fps_confidence = this.createField("", groups.PRIMARY, "FPS confidence", Joi.number().allow("", null));
    this.alert_link_expiration_delay = this.createField(
      "604800",
      groups.PRIMARY,
      "Alert link expiration delay",
      Joi.number()
    );
    this.minimum_alerts_per_hour = this.createField(
      "",
      groups.PRIMARY,
      "Minimum alerts per hour",
      Joi.number().allow("", null)
    );
    this.watcher_alerts = this.createField(false, groups.TOGGLE, "Watcher alerts", Joi.boolean());
    this.watcher_version = this.createField("", groups.PRIMARY, "Watcher version", Joi.string().allow("", null));
    this.good_bandwidth = this.createField(false, groups.TOGGLE, "Good bandwidth", Joi.boolean());
    this.deep_model = this.createField(0, groups.PRIMARY, "Deep model", Joi.number().allow(null));
    this.disable_see_more = this.createField(false, groups.TOGGLE, "Disable see more", Joi.boolean());
    this.disable_video_preview = this.createField(false, groups.TOGGLE, "Disable video preview", Joi.boolean());
    this.anaveo_vt_alert = this.createField(false, groups.TOGGLE, "Anaveo vt alert", Joi.boolean());
    this.ignore_alert_delay = this.createField(false, groups.TOGGLE, "Ignore alert delay", Joi.boolean());
    this.blurring = this.createField(3, groups.PRIMARY, "Blurring", Joi.number().allow(null));
    this.upload_target = this.createField(1, groups.PRIMARY, "Upload Target", Joi.number());
    this.upload_bucket = this.createField(1, groups.PRIMARY, "Upload Bucket", Joi.number());
    this.allow_dataset = this.createField(true, groups.PRIMARY, "Allow Dataset", Joi.boolean());
    this.send_to_app = this.createField(true, groups.PRIMARY, "Send To App", Joi.boolean());
    this.in_furious = this.createField(false, groups.PRIMARY, "In Furious", Joi.boolean());

    /*
    Display  labels
    */
    this.show_cam_number = this.createField(false, groups.TOGGLE, "Show cam number", Joi.boolean());
    this.show_latency = this.createField(false, groups.TOGGLE, "Show latency", Joi.boolean());
    this.show_alert_time = this.createField(false, groups.TOGGLE, "Show alert time", Joi.boolean());
    this.show_alert_type = this.createField(false, groups.TOGGLE, "Show alert type", Joi.boolean());

    /*
    Old alert labels
    */
    this.no_suspicious = this.createField(false, groups.TOGGLE, "No suspicious", Joi.boolean());
    this.no_suspicious_bag = this.createField(false, groups.TOGGLE, "No suspicious bag", Joi.boolean());
    this.no_stroller = this.createField(false, groups.TOGGLE, "No stroller", Joi.boolean());
    this.send_client_normal_bag = this.createField(false, groups.TOGGLE, "Send client normal bag", Joi.boolean());
    this.send_store_normal_bag = this.createField(false, groups.TOGGLE, "Send store normal bag", Joi.boolean());
    this.send_gesture_into_body = this.createField(true, groups.TOGGLE, "Send gesture into body", Joi.boolean());
    this.disable_employee_work = this.createField(false, groups.TOGGLE, "Disable employee work", Joi.boolean());

    /*
    New alert labels
    */
    // Body
    this.bodyToggleGroup = this.createField(false, groups.TOGGLE, "Body labels", Joi.boolean());
    this.body_theft = this.createField(true, groups.TOGGLE, "Body theft", Joi.boolean());
    this.gesture_into_body = this.createField(false, groups.TOGGLE, "Gesture into body", Joi.boolean());
    // Bag
    this.bagToggleGroup = this.createField(false, groups.TOGGLE, "Bag labels", Joi.boolean());
    this.product_in_personal_bag = this.createField(true, groups.TOGGLE, "Product in personal bag", Joi.boolean());
    this.product_into_backpack = this.createField(false, groups.TOGGLE, "Product into backpack", Joi.boolean());
    this.suspicious_bag = this.createField(false, groups.TOGGLE, "Suspicious bag", Joi.boolean());
    this.product_into_stroller = this.createField(false, groups.TOGGLE, "Product into stroller", Joi.boolean());
    this.client_normal_bag = this.createField(false, groups.TOGGLE, "Client normal bag", Joi.boolean());
    this.store_normal_bag = this.createField(false, groups.TOGGLE, "Store normal bag", Joi.boolean());
    // Other sus
    this.othersusToggleGroup = this.createField(false, groups.TOGGLE, "Other suspicious labels", Joi.boolean());
    this.deblistering = this.createField(false, groups.TOGGLE, "Deblistering", Joi.boolean());
    this.consumption = this.createField(false, groups.TOGGLE, "Comsumption", Joi.boolean());
    this.burst_shot = this.createField(false, groups.TOGGLE, "Burst shot", Joi.boolean());
    this.other_suspicious = this.createField(false, groups.TOGGLE, "Other", Joi.boolean());
  }
}

export default StoreModel;
